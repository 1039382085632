<!--
 * @Author: dongjia
 * @Date: 2021-08-20 17:18:33
 * @LastEditTime: 2021-12-20 15:08:46
 * @LastEditors: aleaner
 * @Description: 推荐帖子选择弹窗
 * @FilePath: \saas-admin-vue\src\modules\community-admin\components\postAdmin\RecommandPostSelector.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="选择帖子" :visible.sync="openDialog" width="60%" :show-close="false" class="dialog-vertical"
    @close="cancel">
    <div class="head-filter">
      <!-- <el-cascader v-model="positionIds" :options="OrganizationList" :props="props" placeholder="会内职务">
      </el-cascader> -->
      <el-input v-model="filterForm.keyword" placeholder="请输入帖子内容" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list" style="margin-top: 8px;">
      <el-table :data="postList" style="width: 100%" :height="250" ref="multipleTable" highlight-current-row
        @row-click="handleCurrentChange" v-loading="loading" v-loadmore="LoadMore">
        <!-- 单选 -->
        <el-table-column fixed width="70" :key="Math.random()">
          <template slot-scope="scope">
            <el-radio :label="scope.row" v-model="currentRow">
              {{'&nbsp'}}</el-radio>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column :prop="th.prop" :label="th.label" :width="th.width || ''" :key="index">
            <template slot-scope="scope">
              <el-popover v-if="th.prop === 'content'" placement="bottom" width="374" trigger="hover">
                <div v-html="scope.row.content"></div>
                <!-- popover图片 -->
                <div v-if="scope.row.images.length && scope.row.type === 0" :style="{
                  marginTop: scope.row.content?'13px':''
                }" class="popover-imgs">
                  <el-image v-for="(img,i) in scope.row.images" :key="i" class="popover-img cursor-pointer"
                    :style="{
                    marginTop: index>=3?'16px':''
                  }" :src="fixImageUrl(img.url)" @click="$previewImage([img.url])" fit="cover">
                  </el-image>
                </div>
                <!-- popover视频 -->
                <div class="popover-video" v-if="scope.row.video[0] && scope.row.type === 1" :style="{
                  marginTop: scope.row.content?'13px':''
                }">
                  <el-image class="video-thumbnail" :src="fixImageUrl(scope.row.video[0].thumbnail)"
                    fit="cover">
                  </el-image>
                  <div class="video-mask cursor-pointer" @click="handlePlay(scope.row.video[0])">
                    <img class="video-play" src="@/base/assets/images/media/video-play@2x.png" />
                  </div>
                </div>
                <!-- 帖子内容 -->
                <div class="list-contents" slot="reference" v-html="scope.row.content"
                  v-if="scope.row.content"></div>
                <div class="list-contents" slot="reference" v-else>
                  <el-image v-if="scope.row.images.length && scope.row.type === 0"
                    class="list-icon cursor-pointer" :src="fixImageUrl(scope.row.images[0].url)"
                    @click="$previewImage([scope.row.images[0].url])" fit="cover">
                  </el-image>
                  <div class="content-video" v-if="scope.row.video[0] && scope.row.type === 1">
                    <el-image class="video-thumbnail" :src="fixImageUrl(scope.row.video[0].thumbnail)"
                      fit="cover"></el-image>
                    <div class="video-mask cursor-pointer" @click="handlePlay(scope.row.video[0])">
                      <img class="video-play" src="@/base/assets/images/media/video-play@2x.png" />
                    </div>
                  </div>
                </div>
              </el-popover>
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <template #footer>
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { postOptions } from "../../api/post-admin/list";
import debounce from 'lodash/debounce'
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    // 已选择数据
    selected: {
      type: [Object, String],
      default: "",
    },
    // 当前选中索引
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "内容", prop: "content", minWidth: 150 },
        { label: "发帖者", prop: "author_name", minWidth: 110 },
        { label: "编辑时间", prop: "update_time", minWidth: 160 },
      ],
      postList: [],
      //筛选对象
      filterForm: {
        keyword: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
      },
      getable: true, //是否可以请求列表
      currentRow: "", // 单选时选中的数据项
      positionIds: [],
      OrganizationList: [],
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取会员列表
    getpostOptions: debounce(function (pageData) {
      if (this.getable) {
        this.loading = true;
        postOptions(pageData)
          .then((res) => {
            const { data } = res;
            if (pageData.page === 1) {
              this.postList = data.data;
            } else {
              this.postList = [...this.postList, ...data.data];
            }
            if (data.data.length < this.filterForm.page_size) {
              this.getable = false;
            }
            this.filterForm.page++;
            this.loading = false;
          })
          .catch((err) => {});
      }
    }, 200),
    // 判断是否可选
    selectable(row, index) {
      if (this.selectedPosts.findIndex((item) => item.id === row.id) !== -1) {
        return false;
      } else {
        return true;
      }
    },
    // 筛选操作
    handleFilter(e, key) {
      const fliterData = { ...this.filterForm };
      fliterData.page = key ? fliterData.page : 1;
      this.getable = true;
      this.getpostOptions(fliterData);
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    //触底刷新
    LoadMore() {
      if (this.getable) {
        this.handleFilter(this.filterForm, "Load");
      }
    },
    // 视频播放
    handlePlay(data) {
      this.$store.commit("media/setIsOpenMediaVideoDialog", true);
      this.$store.commit("media/setDialogDetail", data);
    },
    // 确定
    submit() {
      if (!this.currentRow) {
        this.$message.info("请选择数据项");
      } else {
        this.selectedPosts.splice(this.currentIndex, 1, this.currentRow);
        this.$emit("changeSelect", this.selectedPosts[0]);
        this.openDialog = false;
      }
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        const data = { ...this.filterForm };
        this.getpostOptions(data);
      }
    },
    positionIds(val) {
      this.filterForm.position_id = val[val.length - 1]
        ? val[val.length - 1]
        : -1;
      this.filterForm.organization_id = val[0] ? val[0] : -1;
      this.handleFilter(this.filterForm);
    },
    selected: {
      deep: true,
      handler(val) {
        console.log(val, "selected");
        if (!val) {
          this.currentRow = "";
        }
      },
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    selectedPosts: {
      get() {
        return this.selected ? [this.selected] : [];
      },
      set(val) {
        this.$emit("changeSelect", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  width: 190px;
  display: flex;
  position: absolute;
  top: 20px;
  right: 40px;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}

.list-icon {
  width: 60px;
  height: 60px;
}
.black-member {
  display: flex;
  align-items: center;
  .black-tag {
    margin-left: 10px;
    width: 44px;
    height: 28px;
    background-color: rgb(85, 85, 85);
    font-size: 12px;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.list-contents {
  @include clamp(1);
}
.popover-imgs {
  display: flex;
  flex-wrap: wrap;
  .popover-img {
    width: 90px;
    height: 90px;
    margin-right: 16px;
    border-radius: 4px;
  }
}
.content-video {
  position: relative;
  width: 105px;
  height: 60px;
  .video-thumbnail {
    width: 100%;
    height: 100%;
  }
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    .video-play {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}
.popover-video {
  position: relative;
  width: 347px;
  height: 191px;
  .video-thumbnail {
    width: 100%;
    height: 100%;
  }
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    .video-play {
      display: inline-block;
      width: 46px;
      height: 46px;
    }
  }
}
</style>
